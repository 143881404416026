import expiredImg from "../../assets/images/expired.svg"
import checkImage from "../../assets/images/check-circle.png"

export const PaymentLinkExpired = () => {
    return (
        <>
            <div className="expired-content-center">
                <div className="payment-section p-45">
                    <div className="text-center">
                        <img src={expiredImg} className="expired-img" />
                        <h2 className="declaration-heading">Your payment link has expired.</h2>
                        <p className="link-declaration"> Please contact us for a new link.</p>
                    </div>
                </div>
            </div>
        </>
    )

}

export const PaymentSuccesPage = () => {
    return (
        <div className="content-center">
            <div className="payment-section">
                <div className="text-center">
                    <img src={checkImage} className="check-img" alt="Success" />
                    <p className="declaration">Your payment has been successfully processed.</p>
                </div>
            </div>
        </div>
    )
}


export const PaymentCancelledPage = () => {
    return (
        <div className="content-center">
            <div className="payment-section">
                <div className="text-center">
                    <img src={expiredImg} className="check-img" alt="Success" />
                    <p className="declaration">This payment has been cancelled and is no longer valid.</p>
                </div>
            </div>
        </div>
    )
}



